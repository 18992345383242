var prodcat = prodcat || {};

prodcat.ui = prodcat.ui || {};
prodcat.data = prodcat.data || {};

var site = site || {};

site.template = site.template || {};

(function ($) {
  Drupal.behaviors.skuSelectBoxV1 = {
    attach: function (context) {
      var $templates = $('.sku-select-sizes-v1', context);

      $templates.each(function (i, obj) {
        var $template = $(obj);

        MantleSelectBox.attach_sku_selectbox($template);
      });
    }
  };
})(jQuery);
